import React, { createContext, useState } from "react"

export const GeographyContext = createContext()

const initialState = {
  isLoading: false,
  isError: false,
  countries: [],
  states: [],
}

export const GeographyProvider = ({ children }) => {
  /*eslint-disable */
  const [state, setState] = useState(initialState)

  const fetchCountries = async () => {
    setState({ ...state, isLoading: true, isError: false, countries: [] })

    try {
      const countriesCount = await fetch(
        `/.netlify/functions/bigcommerce?version=v2&endpoint=countries/count`,
        { credentials: "same-origin", mode: "same-origin" }
      ).then(res => res.json())

      if (countriesCount.count) {
        const result = await fetch(
          `/.netlify/functions/bigcommerce?version=v2&endpoint=countries?limit=${countriesCount.count}`,
          { credentials: "same-origin", mode: "same-origin" }
        ).then(res => res.json())

        setState({
          ...state,
          isLoading: false,
          isError: false,
          countries: result,
        })
      }
    } catch (error) {
      setState({ ...state, isLoading: false, isError: error })
    }
  }

  const fetchStates = async countryId => {
    setState({ ...state, isLoading: true, isError: false, states: [] })
    try {
      const statesCount = await fetch(
        `/.netlify/functions/bigcommerce?version=v2&endpoint=countries/${countryId}/states/count`,
        { credentials: "same-origin", mode: "same-origin" }
      ).then(res => res.json())

      if (statesCount.count) {
        const result = await fetch(
          `/.netlify/functions/bigcommerce?version=v2&endpoint=countries/${countryId}/states?limit=${statesCount.count}`,
          { credentials: "same-origin", mode: "same-origin" }
        ).then(res => res.json())

        setState({
          ...state,
          isLoading: true,
          isError: false,
          states: result,
        })
      }
    } catch (error) {
      setState({ ...state, isLoading: false, isError: error })
    }
  }

  return (
    <GeographyContext.Provider value={{ state, fetchCountries, fetchStates }}>
      {children}
    </GeographyContext.Provider>
  )
}
