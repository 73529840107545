import React, { createContext, useState, useEffect } from "react"
import { navigate } from "@reach/router"

export const AccountContext = createContext()

const initialState = {
  isSubmitting: false,
  isLoading: false,
  isError: false,
  customer: [],
  wishlists: [],
  products: [],
  addresses: [],
}

export const AccountProvider = ({ children }) => {
  /* eslint-disable */
  const [state, setState] = useState(initialState)

  const fetchCustomer = () => {
    setState({ ...state, isLoading: true })

    fetch(`/.netlify/functions/bigcommerce?endpoint=customers`, {
      credentials: "same-origin",
      mode: "same-origin",
    })
      .then(res => res.json())
      .then(response => {
        setState({
          ...state,
          isLoading: false,
          isError: false,
          customer: response.data,
          addresses: response.data.length && response.data[0].addresses,
        })
      })
      .catch(error => {
        setState({ ...state, isLoading: false, isError: error })
      })
  }

  const login = async data => {
    setState({ ...state, isSubmitting: true, isError: false })

    try {
      const user = await fetch(
        `/.netlify/functions/bigcommerce?endpoint=customers&email=${data.email}`,
        { credentials: "same-origin", mode: "same-origin" }
      ).then(res => res.json())

      if (user && user.data && user.data.length) {
        const isValid = await fetch(
          `/.netlify/functions/bigcommerce?version=v2&endpoint=customers/validate&customer_id=${user.data[0].id}`,
          {
            credentials: "same-origin",
            mode: "same-origin",
            method: "POST",
            body: JSON.stringify({ password: data.password }),
          }
        ).then(res => res.json())

        if (isValid && isValid.success) {
          setState({
            ...state,
            isSubmitting: false,
            isError: false,
            customer: user.data,
            addresses: user.data[0].addresses,
          })
        } else {
          setState({
            ...state,
            isSubmitting: false,
            isError: "Invalid Credentials",
          })
        }
      } else {
        setState({
          ...state,
          isSubmitting: false,
          isError: "Invalid Credentials",
        })
      }
    } catch (error) {
      setState({ ...state, isSubmitting: false, isError: error })
    }
  }

  const logout = () => {
    setState({ ...state, isSubmitting: true, isError: false })

    fetch(`/.netlify/functions/bigcommerce?endpoint=customers/logout`, {
      credentials: "same-origin",
      mode: "same-origin",
    })
      .then(res => {
        if (res.status === 404) {
          setState({ ...initialState, isSubmitting: false })
        }
      })
      .catch(error => {
        setState({ ...state, isSubmitting: false, isError: error })
      })
  }

  const register = (newCustomer, navigateTo) => {
    setState({ ...state, isSubmitting: true, isError: false })

    if (
      newCustomer.authentication.new_password ===
      newCustomer.authentication.confirm_password
    ) {
      fetch(`/.netlify/functions/bigcommerce?endpoint=customers/create`, {
        credentials: "same-origin",
        mode: "same-origin",
        method: "POST",
        body: JSON.stringify([newCustomer]),
      })
        .then(async res => ({ response: await res.json(), status: res.status }))
        .then(({ response, status }) => {
          if (status === 200) {
            setState({
              ...state,
              isSubmitting: false,
              isError: false,
              customer: response.data,
              addresses: response.data[0].addresses,
            })
            navigate(navigateTo ? navigateTo : "/account")
          } else {
            setState({
              ...state,
              isSubmitting: false,
              isError: Object.values(response.errors),
            })
          }
        })
        .catch(error => {
          setState({ ...state, isSubmitting: false, isError: error })
        })
    } else {
      setState({
        ...state,
        isSubmitting: false,
        isError: ["Password mismatch"],
      })
    }
  }

  const validatePassword = async (id, password) => {
    const isValid = await fetch(
      `/.netlify/functions/bigcommerce?version=v2&endpoint=customers/${id}/validate`,
      {
        credentials: "same-origin",
        mode: "same-origin",
        method: "POST",
        body: JSON.stringify({ password }),
      }
    ).then(res => res.json())

    return isValid && isValid.success
  }

  const updateCustomer = async data => {
    setState({ ...state, isSubmitting: true, isError: false })
    let _body = {
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      company: data.company,
      phone: data.phone,
      email: data.email,
    }
    let isProceed = false
    if (data.new_password) {
      //check password is valid - data.password
      //compare data.confirm_password - data.new_password
      if (data.password) {
        if (data.confirm_password === data.new_password) {
          const isValid = await validatePassword(data.id, data.password)
          if (isValid) {
            _body["authentication"] = {
              force_password_reset: false,
              new_password: data.new_password,
            }
            isProceed = true
          } else {
            setState({
              ...state,
              isSubmitting: false,
              isError: { title: "Password is invalid" },
            })
          }
        } else {
          setState({
            ...state,
            isSubmitting: false,
            isError: { title: "Password mismatch" },
          })
        }
      } else {
        setState({
          ...state,
          isSubmitting: false,
          isError: { title: "Please enter your previous password" },
        })
      }
    } else {
      isProceed = true
    }

    if (isProceed) {
      fetch(`/.netlify/functions/bigcommerce?endpoint=customers/update`, {
        credentials: "same-origin",
        mode: "same-origin",
        method: "PUT",
        body: JSON.stringify([_body]),
      })
        .then(async res => ({ response: await res.json(), status: res.status }))
        .then(({ response, status }) => {
          if (status === 200) {
            const { data } = response
            setState({
              ...state,
              isSubmitting: false,
              isError: {
                msgSuccess: true,
                msg: "Customer updated successfully",
              },
              customer: data,
            })
          } else {
            setState({ ...state, isSubmitting: false, isError: response })
          }
        })
        .catch(error => {
          setState({ ...state, isSubmitting: false, isError: error })
        })
    }
  }

  const fetchProductDetails = ({ product_id }) => {
    fetch(
      `/.netlify/functions/bigcommerce?endpoint=catalog/products/${product_id}?include=images`,
      { credentials: "same-origin", mode: "same-origin" }
    )
      .then(res => res.json())
      .then(response => {
        setState(prev => ({
          ...prev,
          products: [...prev.products, response.data],
        }))
      })
      .catch(error => {
        setState({ ...state, isError: error })
      })
  }

  const addToWishlist = (productId, variantId) => {
    setState({ ...state, isSubmitting: productId, isError: false })

    let url
    let data = {
      items: [
        {
          product_id: productId,
          variant_id: variantId,
        },
      ],
    }
    if (state.wishlists.length) {
      url = `/.netlify/functions/bigcommerce?endpoint=wishlists/${state.wishlists[0].id}/items`
    } else {
      url = `/.netlify/functions/bigcommerce?endpoint=wishlists`
      data = {
        ...data,
        name: "My Wish List",
        is_public: true,
        customer_id: state.customer[0].id,
      }
    }

    fetch(url, {
      credentials: "same-origin",
      mode: "same-origin",
      method: "post",
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(response => {
        setState(prev => ({
          ...prev,
          isSubmitting: false,
          wishlists: [response.data, ...prev.wishlists.slice(1)],
        }))
        fetchProductDetails({ product_id: productId })
      })
      .catch(error => {
        setState({ ...state, isSubmitting: false, isError: error })
      })
  }

  const removeFromWishlist = (wishlistId, itemId, productId) => {
    setState({ ...state, isSubmitting: itemId, isError: false })
    if (state.wishlists.length === 0) {
      throw new Error("Wishlist is not created")
    }

    const url = `/.netlify/functions/bigcommerce?endpoint=wishlists/${wishlistId}/items/${itemId}`
    fetch(url, {
      credentials: "same-origin",
      mode: "same-origin",
      method: "delete",
    })
      .then(res => {
        if (res.status === 204) {
          const items = state.wishlists[0].items.filter(
            item => item.id !== itemId
          )
          const products = state.products.filter(
            product => product.id !== productId
          )

          setState(prev => ({
            ...prev,
            isSubmitting: false,
            products,
            wishlists: [
              {
                ...prev.wishlists[0],
                items,
              },
              ...prev.wishlists.slice(1),
            ],
          }))
        }
      })
      .catch(error => {
        setState({ ...state, isSubmitting: false, isError: error })
      })
  }

  const getCustomerAddress = () => {
    setState({ ...state, isError: false, tabLoading: true })

    if (state.customer[0].id) {
      fetch(
        `/.netlify/functions/bigcommerce?endpoint=customers/addresses?customer_id:in=${state.customer[0].id}`,
        { credentials: "same-origin", mode: "same-origin" }
      )
        .then(res => res.json())
        .then(response => {
          setState({
            ...state,
            isError: false,
            tabLoading: false,
            addresses: response.data,
          })
        })
        .catch(error => {
          setState({ ...state, isError: error, tabLoading: false })
        })
    } else {
      console.log("No customer id")
    }
  }

  const addCustomerAddress = data => {
    fetch(`/.netlify/functions/bigcommerce?endpoint=customers/addresses`, {
      credentials: "same-origin",
      mode: "same-origin",
      method: "POST",
      body: JSON.stringify([data]),
    })
      .then(async res => ({ response: await res.json(), status: res.status }))
      .then(({ response, status }) => {
        if (status === 200) {
          setState(prev => ({
            ...prev,
            addresses: [response.data[0], ...prev.addresses],
          }))
        }
      })
      .catch(error => {
        setState({ ...state, isError: error })
      })
  }

  const deleteCustomerAddress = id => {
    setState({ ...state, isError: false, isSubmitting: id })

    fetch(
      `/.netlify/functions/bigcommerce?endpoint=customers/addresses?id:in=${id}`,
      {
        credentials: "same-origin",
        mode: "same-origin",
        method: "DELETE",
      }
    )
      .then(res => {
        if (res.status === 204) {
          setState({
            ...state,
            isError: false,
            isSubmitting: false,
            addresses: state.addresses.filter(add => add.id !== id),
          })
        }
      })
      .catch(error => {
        setState({ ...state, isError: error, isSubmitting: false })
      })
  }

  const fetchWishlists = customerId => {
    fetch(
      `/.netlify/functions/bigcommerce?endpoint=wishlists?customer_id=${customerId}&limit=250`,
      { credentials: "same-origin", mode: "same-origin" }
    )
      .then(res => res.json())
      .then(response => {
        setState(prev => ({ ...prev, wishlists: response.data }))

        response.data.forEach(wishlist => {
          if (wishlist.items.length) {
            wishlist.items.forEach(item => fetchProductDetails(item))
          }
        })
      })
      .catch(error => {
        setState(prev => ({ ...prev, isError: error }))
      })
  }

  // eslint-disable-next-line
  useEffect(() => fetchCustomer(), [])

  return (
    <AccountContext.Provider
      value={{
        state,
        login,
        logout,
        register,
        updateCustomer,
        addToWishlist,
        removeFromWishlist,
        getCustomerAddress,
        addCustomerAddress,
        deleteCustomerAddress,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}
