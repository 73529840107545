import React, { createContext, useState, useEffect } from "react"

export const CheckoutContext = createContext()

const initialState = {
  isLoading: false,
  hasError: false,
  errors: [],
  isSubmitting: false,
  checkout: null,
  countries: [],
  states: [],
}

export const CheckoutProvider = ({ children }) => {
  /* eslint-disable */
  const [state, setState] = useState(initialState)
  const [activeStep, setActiveStep] = useState(0)

  const updateCartByCustomerId = customer_id => {
    setState({ ...state, isLoading: true, hasError: false })

    fetch(`/.netlify/functions/bigcommerce?endpoint=carts`, {
      credentials: "same-origin",
      mode: "same-origin",
      method: "PUT",
      body: JSON.stringify({ customer_id }),
    })
      .then(async res => ({ response: await res.json(), status: res.status }))
      .then(({ status }) => {
        if (status === 200) fetchCheckout(true)
        else setState(initialState)
      })
      .catch(error => {
        setState({ ...state, isLoading: false, hasError: error })
      })
  }

  const fetchCheckout = done => {
    if (!done) setState({ ...state, isLoading: true, hasError: false })

    getAllShippingZones()

    fetch(`/.netlify/functions/bigcommerce?endpoint=checkouts`, {
      credentials: "same-origin",
      mode: "same-origin",
    })
      .then(async res => ({ response: await res.json(), status: res.status }))
      .then(({ response, status }) => refreshCheckout(response, status, 1))
      .catch(error => {
        setState({
          ...state,
          isLoading: false,
          hasError: error,
        })
      })
  }

  const refreshCheckout = (response, status, moveTo) => {
    if (status === 200) {
      setState(prev => ({
        ...prev,
        checkout: response.data,
        isLoading: false,
        hasError: false,
      }))
      setActiveStep(moveTo)
    } else {
      setState(initialState)
    }
  }

  function refreshFetch(URL, METHOD, BODY) {
    fetch(
      URL +
        "?include=consignments.available_shipping_options,cart.line_items.physical_items.options",
      {
        credentials: "same-origin",
        mode: "same-origin",
        method: METHOD,
        body: JSON.stringify(BODY),
      }
    )
      .then(async res => ({ result: await res.json(), status: res.status }))
      .then(response => {
        const { result, status } = response
        if (status === 200) {
          setState(prev => ({
            ...prev,
            checkout: result.data,
            isSubmitting: false,
            hasError: false,
          }))

          setActiveStep(activeStep + 1)
        }
      })
      .catch(error => {
        setState({ ...state, isSubmitting: false, hasError: error })
      })
  }

  const fetchBillingAddress = BODY => {
    setState({ ...state, isSubmitting: true, hasError: false })

    const { id, billing_address } = state.checkout
    if (id) {
      let URL = `/.netlify/functions/bigcommerce?endpoint=checkouts/${id}/billing-address`
      let METHOD = "POST"

      const addressId = billing_address && billing_address.id
      if (addressId) {
        URL = `/.netlify/functions/bigcommerce?endpoint=checkouts/${id}/billing-address/${addressId}`
        METHOD = "PUT"
      }

      refreshFetch(URL, METHOD, BODY)
    } else {
      console.log("Checkout Id is missing")
      setState({ ...initialState, isLoading: false })
    }
  }

  function fetchConsignments(data) {
    setState({ ...state, isSubmitting: true, hasError: false })

    const { id, consignments } = state.checkout
    if (id) {
      const line_items = state.checkout.cart.line_items.physical_items.map(
        item => ({
          item_id: item.id,
          quantity: item.quantity,
        })
      )

      let URL = `/.netlify/functions/bigcommerce?endpoint=checkouts/${id}/consignments`
      let METHOD = "POST"
      let BODY = [{ ...data, line_items }]

      if (data.shipping_option_id) {
        const consignmentId =
          consignments && consignments.length > 0 && consignments[0].id
        URL = `/.netlify/functions/bigcommerce?endpoint=checkouts/${id}/consignments/${consignmentId}`
        METHOD = "PUT"
        BODY = data
      }

      refreshFetch(URL, METHOD, BODY)
    } else {
      console.log("Checkout Id is missing")
      setState({ ...initialState, isLoading: false })
    }
  }

  function fetchProcessPayments(payment) {
    setState({ ...state, isSubmitting: true, hasError: false, errors: [] })
    //get token
    fetch(`/.netlify/functions/bigcommerce?endpoint=payments/access_tokens`, {
      credentials: "same-origin",
      mode: "same-origin",
      method: "POST",
      body: JSON.stringify({
        order: {
          id: state.order_id,
        },
      }),
    })
      .then(res2 => res2.json())
      .then(res22 => {
        if (res22.data && res22.data.id) {
          //process payment
          const token = res22.data.id
          fetch(`/.netlify/functions/bigcommerce?endpoint=payments`, {
            credentials: "same-origin",
            mode: "same-origin",
            method: "POST",
            body: JSON.stringify(payment),
            headers: { Authorization: `PAT ${token}` },
          })
            .then(async res => ({
              response: await res.json(),
              status: res.status,
            }))
            .then(({ response, status }) => {
              if (status === 201) {
                setActiveStep(activeStep + 1)
                setState({
                  ...state,
                  isSubmitting: false,
                  hasError: false,
                  order_transaction: response.data,
                })
              } else {
                setState({
                  ...state,
                  isSubmitting: false,
                  hasError: response.title,
                  errors: Object.values(response.errors),
                })
              }
            })
            .catch(error => {
              setState({
                ...state,
                isSubmitting: false,
                hasError: error,
                errors: [],
              })
            })
        }
      })
      .catch(error => {
        setState({
          ...state,
          isSubmitting: false,
          hasError: error,
        })
      })
  }

  const getAllShippingZones = async () => {
    setState(prev => ({ ...prev, hasError: false, countries: [] }))

    fetch(
      `/.netlify/functions/bigcommerce?version=v2&endpoint=shipping/zones`,
      { credentials: "same-origin", mode: "same-origin" }
    )
      .then(async res => ({ response: await res.json(), status: res.status }))
      .then(({ response, status }) => {
        if (status === 200) {
          if (response && response.length) {
            response
              .filter(country => country.enabled)
              .forEach(item => {
                getCountry(item.locations[0].country_iso2)
              })
          }
        }
      })
      .catch(error => {
        setState(prev => ({ ...prev, hasError: error, countries: [] }))
      })
  }

  const getCountry = async country_iso2 => {
    const country = await fetch(
      `/.netlify/functions/bigcommerce?version=v2&endpoint=countries?country_iso2=${country_iso2}`,
      { credentials: "same-origin", mode: "same-origin" }
    ).then(res => res.json())

    if (country.length)
      setState(prev => ({
        ...prev,
        countries: [...prev.countries, country[0]],
      }))
  }

  const getAllCountryStates = id => {
    setState(prev => ({ ...prev, hasError: false, states: [] }))

    if (id) {
      fetch(
        `/.netlify/functions/bigcommerce?version=v2&endpoint=countries/${id}/states?limit=250`,
        { credentials: "same-origin", mode: "same-origin" }
      )
        .then(res => res.json())
        .then(response => {
          setState(prev => ({
            ...prev,
            hasError: false,
            states: response,
          }))
        })
        .catch(error => {
          setState(prev => ({ ...prev, hasError: error, states: [] }))
        })
    }
  }

  useEffect(() => {
    function fetchCreateOrder() {
      setState({ ...state, isSubmitting: true, hasError: false })

      const { id } = state.checkout
      if (id) {
        //create order
        fetch(
          `/.netlify/functions/bigcommerce?endpoint=checkouts/${id}/orders`,
          {
            credentials: "same-origin",
            mode: "same-origin",
            method: "POST",
            body: JSON.stringify({ id: "" }),
          }
        )
          .then(res => res.json())
          .then(response => {
            if (response.data && response.data.id) {
              //get payment method
              fetch(
                `/.netlify/functions/bigcommerce?endpoint=payments/methods?order_id=${response.data.id}`,
                {
                  credentials: "same-origin",
                  mode: "same-origin",
                }
              )
                .then(res1 => res1.json())
                .then(res11 => {
                  setState({
                    ...state,
                    order_id: response.data.id,
                    payment_method: res11.data,
                  })
                })
                .catch(error => {
                  setState({ ...state, isSubmitting: false, hasError: error })
                })
            }
          })
          .catch(error => {
            setState({ ...state, isSubmitting: false, hasError: error })
          })
      } else {
        console.log("Checkout Id is missing")
        setState({ ...state, isSubmitting: false, hasError: false })
      }
    }

    if (activeStep === 5) {
      fetchCreateOrder()
    }
  }, [activeStep])

  return (
    <CheckoutContext.Provider
      value={{
        state,
        activeStep,
        setActiveStep,
        fetchCheckout,
        fetchBillingAddress,
        fetchConsignments,
        fetchProcessPayments,
        getAllShippingZones,
        getAllCountryStates,
        updateCartByCustomerId,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}
