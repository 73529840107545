import React from "react"
import { CartProvider } from "./src/context/CartContext"
import { CheckoutProvider } from "./src/context/CheckoutContext"
import { AccountProvider } from "./src/context/AccountContext"
import { GeographyProvider } from "./src/context/GeographyContext"

export const wrapRootElement = ({ element }) => (
  <GeographyProvider>
    <AccountProvider>
      <CheckoutProvider>
        <CartProvider>{element}</CartProvider>
      </CheckoutProvider>
    </AccountProvider>
  </GeographyProvider>
)
